import * as React from 'react'
import { hot } from 'react-hot-loader/root'
import { Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

import history from './history'

import store from './store'
import Auth from './auth/Auth'
import Login from './auth/Login'
import Header from './common/header/Header'
import Orders from './orders/Orders'
import Order from './orders/Order'
import Accounts from './accounts/Accounts'
import InternalUsers from './users/InternalUsers'
import Plan from './plan/Plan'
import Product from './product/Product'
import AccountUsers from './accounts/accountUsers/AccountUsers'
import AccountLinks from './accounts/accountLinks/AccountLinks'
import AccountSettings from './accounts/accountSettings/AccountSettings'
import AccountContext from './accounts/AccountContext'
import Meta from './auth/Meta'
import ManagerUsers from './manager/users/Users'
import ManagerGroups from './manager/managerGroups'
import ManagerInventory from './manager/inventory/Inventory'
import ManagerInboundInventories from './manager/inboundInventories'
import ManagerInboundInventory from './manager/inboundInventory'
import ManagerOrders from './manager/orders/Orders'
import ManagerOrder from './manager/orders/Order/Order'
import ShoppingCart from './manager/orders/Order/ShoppingCart'
import {getShouldRenderNewReplenishmentPlan} from "./common/getShouldRenderNewReplenishmentPlan";

const App: React.FunctionComponent = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Meta>
          <Switch>
            <Route path="/login" component={Login} />
            <Route
              render={() => (
                <Auth>
                  {user => (
                    <>
                      <Header />
                      <Switch>
                        {user.role === 'manager' ? (
                          <>
                            <Route path="/users" component={ManagerUsers} />
                            <Route path="/groups" component={ManagerGroups} />
                            <Route
                              path="/inbound_inventories/:inboundInventoryId"
                              component={ManagerInboundInventory}
                            />
                            <Route
                              path="/inbound_inventories"
                              component={ManagerInboundInventories}
                            />
                            {getShouldRenderNewReplenishmentPlan(
                              user?.account?.replenishmentPlanVersion
                            ) &&
                                <Route
                                  path="/place_order"
                                  component={ShoppingCart}
                                />
                            }
                            <Route
                              exact
                              path="/inventory"
                              component={ManagerInventory}
                            />
                            <Route
                              path="/product/:productId"
                              component={Product}
                            />
                            <Route path="/plan/:productId?" component={Plan} />
                            <Route
                              path="/orders/:orderId"
                              component={ManagerOrder}
                            />
                            <Route path="/orders" component={ManagerOrders} />
                            <Route
                              exact
                              path="/requests"
                              render={() => <Redirect to="/orders" />}
                            />
                            <Route
                              exact
                              path="/"
                              render={() => (
                                <Redirect
                                  to={
                                    user.generalManager
                                      ? '/orders'
                                      : '/inventory'
                                  }
                                />
                              )}
                            />
                          </>
                        ) : (
                          <>
                            <Route
                              path="/clients/:accountId/"
                              strict
                              render={({ match }) => (
                                <AccountContext.Provider
                                  value={Number(match.params.accountId)}
                                >
                                  <Switch>
                                    <Route
                                      path="/clients/:accountId/users"
                                      component={AccountUsers}
                                    />
                                    <Route
                                      path="/clients/:accountId/links"
                                      component={AccountLinks}
                                    />
                                    <Route
                                      path="/clients/:accountId/settings"
                                      component={AccountSettings}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/users"
                                      component={ManagerUsers}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/groups"
                                      component={ManagerGroups}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/inventory"
                                      component={ManagerInventory}
                                    />
                                    <Route
                                      // tslint:disable-next-line: max-line-length
                                      path="/clients/:accountId/branches/:branchId/inbound_inventories/:inboundInventoryId"
                                      component={ManagerInboundInventory}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/inbound_inventories"
                                      component={ManagerInboundInventories}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/orders/:orderId"
                                      component={ManagerOrder}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/orders"
                                      component={ManagerOrders}
                                    />
                                    <Route
                                      exact
                                      path="/clients/:accountId/branches/:branchId/requests"
                                      render={() => (
                                        <Redirect to="/clients/:accountId/branches/:branchId/orders" />
                                      )}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/:productId"
                                      component={Product}
                                    />
                                    <Route
                                      path="/clients/:accountId/branches/:branchId/:productId?"
                                      component={Plan}
                                    />
                                  </Switch>
                                </AccountContext.Provider>
                              )}
                            ></Route>
                            <Route path="/clients" component={Accounts} />
                            {user.role === 'internal' && (
                              <>
                                <Route
                                  path="/orders"
                                  exact
                                  component={Orders}
                                />
                                <Route
                                  path="/orders/:id"
                                  exact
                                  component={Order}
                                />
                              </>
                            )}
                            <Route path="/users" component={InternalUsers} />
                            <Route
                              exact
                              path="/"
                              render={() => <Redirect to="/clients" />}
                            />
                          </>
                        )}
                      </Switch>
                    </>
                  )}
                </Auth>
              )}
            />
          </Switch>
        </Meta>
      </ConnectedRouter>
    </Provider>
  )
}

export default hot(App)
