import React, { FC, memo } from 'react'
import cn from 'classnames'
import { includes } from 'lodash/fp'
import { Link } from 'react-router-dom'
import { IDetailsOrder } from '../../../types/orders'
import CrossIcon from '../../../icons/cross.svg'
import OrderStatus from './OrderStatus'

interface IOrderHeaderProps {
  order: IDetailsOrder
  onNumberEdit: () => void
  onClose: () => void
  gmFlow?: boolean
}

const OrderHeader: FC<IOrderHeaderProps> = ({
  order,
  onNumberEdit,
  onClose,
  gmFlow,
}) => {
  const submitted = includes(order.status, ['submitted', 'skipped'])

  return (
    <div className="order-modal_head">
      <div className="order-modal_head-number form">
        <div className="order-modal_title">Order&nbsp;</div>
        <div
          className={cn('order-modal_number', { '-disabled': submitted })}
          onClick={onNumberEdit}
        >
          {submitted ? (
            <>
              <span style={{ color: '#E4922F' }}>{order.navOrderId}</span>
              {order.navOrderId && order.purchaseOrderNumber && <>&nbsp;/&nbsp;</>}
              {order.purchaseOrderNumber}
            </>
          ) : (
            order.purchaseOrderNumber || 'Add PO Number'
          )}
        </div>
        <Link to="#" className="order-modal_back" onClick={onClose}>
          <CrossIcon width={15} height={16} />
        </Link>
      </div>
      <OrderStatus order={order} gmFlow={gmFlow} />
    </div>
  )
}

export default memo(OrderHeader)
