import React, { FC, memo } from 'react'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import CrossIcon from '../../../icons/cross.svg'
import {IBranch} from "../../../types/user";

interface ICartHeaderProps {
  branch: IBranch,
  onNumberEdit: () => void
  onClose: () => void
}

const ShoppingCartHeader: FC<ICartHeaderProps> = ({
                                              branch,
                                              onNumberEdit,
                                              onClose,
                                            }) => {
  return (
    <div className="order-modal_head">
      <div className="order-modal_head-number form">
        <div className="order-modal_title"></div>
        <div
          className={cn('order-modal_number', { '-disabled': false })}
          onClick={onNumberEdit}
        >
          {branch.purchaseOrderNumber || 'Add PO Number'}
        </div>
        <Link to="#" className="order-modal_back" onClick={onClose}>
          <CrossIcon width={15} height={16} />
        </Link>
      </div>
    </div>
  )
}

export default memo(ShoppingCartHeader)
