import React, { FC, useCallback } from 'react'
import cn from 'classnames'
import { ListChildComponentProps } from 'react-window'
import PlaceholderIcon from '../../components/OrderSnippet/placeholder.svg'
import {ICartItem, IOrderItem} from '../../types/orders'
import { IProduct } from '../../types/products'
import Button from '../../components/Button'
import ShoppingCartRow from "./ShoppingCartRow";

const ShoppingCartProductRow: FC<ListChildComponentProps> = ({
                                                        data,
                                                        index,
                                                        style,
                                                      }) => {
  const product: IProduct = data.products[index]
  const relatedCartItem: IOrderItem = data.cartItems.find(
    (oi: ICartItem) => oi.product.id === product.id
  )
  const price = [`$${product.price}`, product.unit].filter(Boolean).join(' / ')
  const isEditable = data.editable
  const multiCatalogEnabled = data.multiCatalogEnabled
  const handleAdd = useCallback(() => {
    data.onAdd(product)
  }, [data.onUpdate, product])
  if (relatedCartItem)
    return (
      <ShoppingCartRow
        data={{ ...data, cartItem: relatedCartItem }}
        index={index}
        style={style}
      />
    )
  return (
    <div
      className={cn('order-modal_list-item', {
        '-product': isEditable,
        '-full-width': !isEditable,
      })}
      style={style}
    >
      <div className="order-modal_list-product order-snippet -small">
        <div className="order-snippet_col -info -large">
          <div className="order-snippet_image-wrapper">
            {product.photo ? (
              <img className="order-snippet_image" src={product.photo} alt="" />
            ) : (
              <PlaceholderIcon className="order-snippet_image" />
            )}
          </div>
          <div className="order-snippet_title">{product.name}</div>
          <div className="order-snippet_info">
            <div className="order-snippet_sku">{product.code}</div>
            {multiCatalogEnabled && <div className="order-snippet_erp-catalog">
              {product.erpCatalogName}
            </div>}
          </div>
          <div className="order-snippet_price">{price}</div>
        </div>
      </div>
      {isEditable && (
        <Button
          className="tile clear-button -bordered order-modal_list-action"
          onClick={handleAdd}
          color="link"
          disabled={data.loading}
        >
          Add To Cart
        </Button>
      )}
    </div>
  )
}

export default ShoppingCartProductRow
