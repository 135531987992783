import React, { FC } from 'react'
import { NavLink } from 'react-router-dom'
import withAccountAndBranch from '../withAccountAndBranch'
import {IBranch, IUser} from '../../types/user'
import { withRouter } from 'react-router'
import {cartTotal} from "../orders";
import {getShouldRenderNewReplenishmentPlan} from "../getShouldRenderNewReplenishmentPlan";

interface IOwnProps {
  user: IUser,
  branch: IBranch
}

const SubHeaderManager: FC<IOwnProps> = ({ user, branch }) => {
  const ordersEnabled = (user.account?.ordersEnabled) ?? true;
  const shouldRenderNewReplenishmentPlan = getShouldRenderNewReplenishmentPlan(
    user.account!.replenishmentPlanVersion
  )

  return (
    <div className="subheader_menu">
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/plan"
      >
        Replenishment Plan
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/inventory"
      >
        Inventory
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/users"
      >
        Users
      </NavLink>
      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/groups"
      >
        Divisions & Teams
      </NavLink>
      {ordersEnabled && (
        <NavLink
          activeClassName="-active"
          className="menu_root-link link"
          to="/orders"
        >
          Orders
        </NavLink>
      )}

      <NavLink
        activeClassName="-active"
        className="menu_root-link link"
        to="/inbound_inventories"
      >
        Inbound Inventory
      </NavLink>

      {shouldRenderNewReplenishmentPlan && (
         <div className="subheader_menu-right">
           {branch && branch.cartItems &&
               <div className="menu_root-cart-info">
                 {branch.cartItems.length > 0 ? (
                   <>
                     Planned for order{' '}
                     <span className="highlight">
                       {branch.cartItems.length} items | {cartTotal(branch)}
                     </span>
                   </>
                 ) : (
                   'Nothing planned'
                 )}
               </div>
           }

           <NavLink
             className="menu_root-place-order-button"
             to="/place_order"
           >
             Place order
           </NavLink>
         </div>
      )}
    </div>
  )
}

export default withRouter(withAccountAndBranch(SubHeaderManager))
